.CV {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CVTable {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.CVFieldHeader {
  text-align: left;
  padding-top: 1em;
}

.CVFieldHeader.first {
  border-top: none;
  padding-top: 0;
}

.CVFieldHeader h3 {
  margin: 0;
  text-decoration: underline;
}

.CVEntry {
  margin-bottom: 1em;
}

.CVEntry .Year {
  vertical-align: top;
  padding-right: 0.5em;
}