.Content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .Content {
    width: 100%;
  }
}