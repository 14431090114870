@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);
body {
  margin: 0;
  padding: 0;
  color: #526a7f;
  font-family: 'Libre Baskerville', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100vw;
	min-height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	background-color: white;
}
.Header {
  z-index: 100;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  -webkit-flex: 0 1;
          flex: 0 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  /* background-color: inherit; */
  padding-left: 1em;
  padding-right: 1em;
  opacity: 1;
  -webkit-transition: opacity 1000ms, background-color 2500ms ease-in;
  transition: opacity 1000ms, background-color 2500ms ease-in;
}

.Header.onVideoPage {
  opacity: 0;
  -webkit-transition: opacity 1000ms;
  transition: opacity 1000ms;
  /* background-color: transparent */
}

.Header.onVideoPage.Mobile, .Header.onVideoPage.mouseMoved {
  opacity: 1;
}

.Header a {
  -webkit-transition: color 1000ms;
  transition: color 1000ms;
  text-decoration: none;
}

.Header.onVideoPage a {
  -webkit-transition: color 1000ms;
  transition: color 1000ms;
}



.MainMenu {
  z-index: 100;
  display: -webkit-flex;
  display: flex;
  min-width: 38.2vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.MainMenuItemLink {
  cursor: pointer;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.MainMenuItem {
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 1em;
}

a.MainMenuItemLink {
  text-decoration: none;
}

.MobileMenuContainer {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.MobileMenuIcon {
  cursor: pointer;
}

.IconSVG {
  width: auto;
  height: 2em;
}

.MobileMenu {
  position: absolute;
  top: 100%;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  min-width: 38.2vw;
}

.MobileMenuBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -1;
  opacity: 0.8;
}

a.Mobile.MenuItemLink {
  color: orangered;
  text-decoration: none;
}

.Mobile.MenuItemLink {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  white-space: nowrap;
  overflow: hidden;
  min-width: 75px;
}

.Mobile.MenuItemLink.first {
  border-top: 1px solid black;
}


.DropdownBackground {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.MainMenuItemDropdown {
  min-width: 25vw;
  z-index: 100;
  position: absolute;
  right: 0;
  top: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
}

.Mobile.MainMenuItemDropdown {
  right: 100%;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  max-width: calc(100vw - 38.2vw - 2em);
}

.DropdownItemLink {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  white-space: nowrap;
  overflow: hidden;
  min-width: 75px;
}

.Mobile .DropdownItemLink {
  /* white-space: normal; */
  text-overflow: ellipsis;
}

.DropdownItemLink.first {
  border-top: 1px solid black;
}

a.DropdownItemLink {
    text-decoration: none;
}

.DropdownItem {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.Content {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

@media (max-width: 767px) {
  .Content {
    width: 100%;
  }
}
.RouteView.fade-enter {
  opacity: 0;
  z-index: 1;
}

.RouteView.DetenidosDesarecidos1 {
  height: 80vh;
}

.VideoPage.fade-enter {
  opacity: 1;
  z-index: 1;
}

.RouteView.fade-enter.fade-enter-active,
.VideoPage.fade-enter.RouteView.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}

.RouteView.fade-exit {
  display: none;
}

.RouteView.Home.fade-exit, 
.VideoPage.fade-exit {
  display: -webkit-flex;
  display: flex; 
  opacity: 1;
  z-index: -100;
}

.RouteView.Home.fade-exit.fade-exit-active
.VideoPage.fade-exit.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}


.youtubeContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.youtubeVid {
  width: 560px;
  height: 315px;
}

.citedWork {
  text-indent: -2em;
  position: relative;
  left: 2em;
}

.ViewButtonContainer {
  padding: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}

.ViewButton {
  width: 30%;
  height: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  position: relative;
}

.ViewButtonImage {
  max-width: 100%;
  min-height: 100%;
}

.ViewButtonLayover {
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50;
	opacity: 0;
	-webkit-transition: opacity 1s;
	transition: opacity 1s;
	background-color: white;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.ViewButtonLayover.hover {
	opacity: 0.8;
	-webkit-transition: opacity 1s;
	transition: opacity 1s;
}

a.ViewButtonLink {
  color: #526a7f;
}






.RouteView {
  margin-top: 1em;
  margin-bottom: 1em;
  width: 1127px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center
}

@media (max-width: 767px) {
  .RouteView {
    width: calc(100% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .RouteView {
    width: 723px;
  }
}
@media (min-width: 992px) and (max-width: 1200px){
  .RouteView {
    width: 933px;
  }
}
.RouteView.Home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.CarouselButton {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  padding: 2em 1em;
  z-index: 100;
}

.CarouselButton.hidden {
  visibility: hidden;
}


/* PAGE CONTROL INDEX */
.CarouselIndexer {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0.5em;
  z-index: 100;
}

.CarouselIndexIcon {
  width: 10px;
  height: 10px;
}
.VideoPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: black;
}

.VideoPage > .video {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.audioToggleButton {
  position: fixed !important;
  bottom: 0;
  right: 0;
  padding: 1rem;
  cursor: pointer;
}

.audioToggleIcon {
  margin-right: 0 !important;
}
.CV {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.CVTable {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.CVFieldHeader {
  text-align: left;
  padding-top: 1em;
}

.CVFieldHeader.first {
  border-top: none;
  padding-top: 0;
}

.CVFieldHeader h3 {
  margin: 0;
  text-decoration: underline;
}

.CVEntry {
  margin-bottom: 1em;
}

.CVEntry .Year {
  vertical-align: top;
  padding-right: 0.5em;
}
.Press {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.PressFieldHeader {
  text-align: left;
}

.PressFieldHeader h3 {
  margin: 0;
  text-decoration: underline;
}

.PressEntryYear {
  vertical-align: top;
  padding-right: 0.5em;
}

.PressEntrySpacer {
  height: 1em;
}

a.PressEntryLink {
  text-decoration: none;
  color: #526a7f
}

a.PressEntryLink:hover {
  text-decoration: line-through;
  cursor: pointer;
}

a.PressEntryLink:active {
  text-decoration: line-through;
}

/* a.MainMenuItemLink, a.DropdownItemLink {
  color: #A9A9A9;
} */
.CuratorialItem {
  display: -webkit-flex;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
}

.CuratorialItemLink {
  max-width: 38%;
  padding-right: 2em;
}

.CuratorialItemImage {
  width: 100%;
}

.CuratorialItemContent {
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-self: center;
          align-self: center;
}

.CuratorialItemContent > p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
}
