.MainMenu {
  z-index: 100;
  display: flex;
  min-width: 38.2vw;
  justify-content: space-between;
}

.MainMenuItemLink {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainMenuItem {
  position: relative;
  justify-content: center;
  align-items: center;
  padding-left: 1em;
}

a.MainMenuItemLink {
  text-decoration: none;
}
