.CuratorialItem {
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
}

.CuratorialItemLink {
  max-width: 38%;
  padding-right: 2em;
}

.CuratorialItemImage {
  width: 100%;
}

.CuratorialItemContent {
  flex: 1;
  align-self: center;
}

.CuratorialItemContent > p {
  margin-block-start: 0;
}