.VideoPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.VideoPage > .video {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.audioToggleButton {
  position: fixed !important;
  bottom: 0;
  right: 0;
  padding: 1rem;
  cursor: pointer;
}

.audioToggleIcon {
  margin-right: 0 !important;
}