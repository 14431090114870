.RouteView.Home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.CarouselButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 2em 1em;
  z-index: 100;
}

.CarouselButton.hidden {
  visibility: hidden;
}


/* PAGE CONTROL INDEX */
.CarouselIndexer {
  align-self: flex-end;
  display: flex;
  margin-bottom: 0.5em;
  z-index: 100;
}

.CarouselIndexIcon {
  width: 10px;
  height: 10px;
}