.Header {
  z-index: 100;
  position: sticky;
  top: 0;
  flex: 0;
  display: flex;
  justify-content: space-between;
  /* background-color: inherit; */
  padding-left: 1em;
  padding-right: 1em;
  opacity: 1;
  transition: opacity 1000ms, background-color 2500ms ease-in;
}

.Header.onVideoPage {
  opacity: 0;
  transition: opacity 1000ms;
  /* background-color: transparent */
}

.Header.onVideoPage.Mobile, .Header.onVideoPage.mouseMoved {
  opacity: 1;
}

.Header a {
  transition: color 1000ms;
  text-decoration: none;
}

.Header.onVideoPage a {
  transition: color 1000ms;
}


