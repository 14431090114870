.Press {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PressFieldHeader {
  text-align: left;
}

.PressFieldHeader h3 {
  margin: 0;
  text-decoration: underline;
}

.PressEntryYear {
  vertical-align: top;
  padding-right: 0.5em;
}

.PressEntrySpacer {
  height: 1em;
}

a.PressEntryLink {
  text-decoration: none;
  color: #526a7f
}

a.PressEntryLink:hover {
  text-decoration: line-through;
  cursor: pointer;
}

a.PressEntryLink:active {
  text-decoration: line-through;
}

/* a.MainMenuItemLink, a.DropdownItemLink {
  color: #A9A9A9;
} */