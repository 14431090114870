.youtubeContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.youtubeVid {
  width: 560px;
  height: 315px;
}

.citedWork {
  text-indent: -2em;
  position: relative;
  left: 2em;
}

.ViewButtonContainer {
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.ViewButton {
  width: 30%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.ViewButtonImage {
  max-width: 100%;
  min-height: 100%;
}

.ViewButtonLayover {
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50;
	opacity: 0;
	transition: opacity 1s;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ViewButtonLayover.hover {
	opacity: 0.8;
	transition: opacity 1s;
}

a.ViewButtonLink {
  color: #526a7f;
}






.RouteView {
  margin-top: 1em;
  margin-bottom: 1em;
  width: 1127px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

@media (max-width: 767px) {
  .RouteView {
    width: calc(100% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .RouteView {
    width: 723px;
  }
}
@media (min-width: 992px) and (max-width: 1200px){
  .RouteView {
    width: 933px;
  }
}