.MobileMenuContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileMenuIcon {
  cursor: pointer;
}

.IconSVG {
  width: auto;
  height: 2em;
}

.MobileMenu {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 38.2vw;
}

.MobileMenuBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -1;
  opacity: 0.8;
}

a.Mobile.MenuItemLink {
  color: orangered;
  text-decoration: none;
}

.Mobile.MenuItemLink {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  white-space: nowrap;
  overflow: hidden;
  min-width: 75px;
}

.Mobile.MenuItemLink.first {
  border-top: 1px solid black;
}

