.DropdownBackground {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.MainMenuItemDropdown {
  min-width: 25vw;
  z-index: 100;
  position: absolute;
  right: 0;
  top: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.Mobile.MainMenuItemDropdown {
  right: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: calc(100vw - 38.2vw - 2em);
}

.DropdownItemLink {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  white-space: nowrap;
  overflow: hidden;
  min-width: 75px;
}

.Mobile .DropdownItemLink {
  /* white-space: normal; */
  text-overflow: ellipsis;
}

.DropdownItemLink.first {
  border-top: 1px solid black;
}

a.DropdownItemLink {
    text-decoration: none;
}

.DropdownItem {
  margin-left: 0.5em;
  margin-right: 0.5em;
}