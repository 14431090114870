.RouteView.fade-enter {
  opacity: 0;
  z-index: 1;
}

.RouteView.DetenidosDesarecidos1 {
  height: 80vh;
}

.VideoPage.fade-enter {
  opacity: 1;
  z-index: 1;
}

.RouteView.fade-enter.fade-enter-active,
.VideoPage.fade-enter.RouteView.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.RouteView.fade-exit {
  display: none;
}

.RouteView.Home.fade-exit, 
.VideoPage.fade-exit {
  display: flex; 
  opacity: 1;
  z-index: -100;
}

.RouteView.Home.fade-exit.fade-exit-active
.VideoPage.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

